@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap%27');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.container{
overflow: hidden;
}

a {
    text-decoration: none;
}
.separator {
    border-top: 1px solid #F1F1F1;
    margin-block: 40px;
}
h1 {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: italic;
}

/*Primer Banner*/
.banner-container {
    display: flex;
    justify-content: center;
    z-index: -1000;
}
.banner-container img {
    width: 100%;  
}

#below-banner{
  border-radius: 60px 60px 0px 0px;
 height:130px;
  /*background: #0E0D0D;*/
backdrop-filter: blur(5px);
  background:rgb(14,13,13, 0.7);


color: #F1F1F1;
font-family: 'Barlow Semi Condensed', sans-serif;
font-size: 20px;
margin-top: -100px;
padding: 0px 10px 0px 30px;

}


#below-banner h1 {
    margin-top:30px;
    margin-left: 15px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: italic;
    font-size: 70px;
    margin-bottom: 0px;
}

#below-banner p {
    font-size: 15px;
    margin-bottom: 40px;
    margin-top: 10px;
        margin-left: 15px;
}

#below-banner a {
    font-weight: bold;
    letter-spacing:0.05em;
    margin-top: 10px;
    background: #FF562E;
    padding: 15px 15px;
    border-radius: 5px;
    color: #F1F1F1;
    margin-left: 15px;
    font-family: 'Inter', sans-serif;

}
#below-banner a:hover {
    background: #F1F1F1;
    color: #FF562E;
    transition: background 150ms ease-in;
}

/*configuracion del terxto debajo de BUY WALRUS*/
#ProgetDefinition{
font-weight: initial;
font-family: 'Roboto', sans-serif;
font-size: 16.5px;
line-height:26px;

}

#subtituloPrincipal{
    display:inline;
    font-weight: initial;
font-family: 'Roboto', sans-serif;
    font-size: 16px;
line-height:40px;
}

#colorNaranja{
    
    font-family: 'Roboto', sans-serif;
    color: #FF562E;
    font-size: 14px;
    display: inline;
}


/*FIRST SECTION*/

#first-section {
    justify-content: space-around !important;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
}
#first-section img {
    width: 33%;
    border-radius: 20px;
}
#first-section div h2 {
    margin: 0;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: italic;
    line-height: 100%;
}
#first-section div #walrus {
    font-size: 60px;
    color: #FF562E;
}
#first-section div {
    width: 58%;
}

#picture1{
    margin-top: 0%;
}



/*SECOND SECTION*/
#second-section {
    margin-top: -4%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
#second-section2 {

    flex-direction: column;
    justify-content: center;
    align-items: center;

}

#second-section h1 {
    margin-top: 80px;
    font-size: 60px;

}

#second-section2 h1 {
    margin-top: 80px;
    font-size: 60px;

}

#second-section #roadmap {
     padding-bottom: 10%;
}

#second-section #roadmap p{
    font-family: 'Roboto', sans-serif;
        margin-block-start: 5px;

}


#second-section #roadmap img {
    width:70%;
    margin-left: -13.5%;

}


#roadmap{
        display: flex;
   flex-direction: row;
align-items: center;
margin-left: 12%;

}

.colorChicoNaranja{
    display:inline;
font-size: 14.63px;
color: #da6744;
margin-left: 5px;

}

#second-section #referencias{
        font-style: italic;
       font-size: 43px;
    align-self: flex-end !important;
     align-self: left;
    text-align: right;
font-family: 'Barlow Semi Condensed';
letter-spacing: 2px;
  color: #FF562E;

}

#second-section #text{
    margin-top: -6%;
}

#second-section h3{
    display:inline;
    font-style: italic;
    font-size: 23px;
  }

#second-section h4{
  font-size: 26px;
     display:inline;
         font-style: italic;
            color: #d84b20;
}


#second-section2 #galeria{
    padding-top: 10px;
}

#second-section2 #images {
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    gap: 15px;
    padding-top: 80px;
}
#second-section2 #images img {
    width: 30%;
    aspect-ratio: 1;
    border-radius: 15px;
}

/*...............................................*/

#second-section2 #below-images {
/*PROBLEMON SI QUIERO CAMIAR ALGO*/
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top:30px;
margin-right: 5%;
margin-bottom: 10px;

}

#second-section2 #below-images p {
    font-size: 18px;
    margin-right: 15px;
    display: flex;
    margin-left: 5px;
    color: #dd6946;
}
/*--------BUY WALRUS BELOW IMAGES --------*/
/*
#second-section2 #below-images a {
    background: #FF562E;
    padding: 15px 15px;
    border-radius: 5px;
    color: #F1F1F1;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;

}
#second-section2 #below-images a:hover {
    background: #F1F1F1;
    color: #FF562E;
    transition: background 150ms ease-in;
}
*/
#second-section2 #below-images a {
    background: #0E0D0D;
    color:white;
    transition: background 150ms ease-in;

    padding: 15px 15px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    cursor: not-allowed;
    border-color:white;
    border-width: 2px;
    border-style:solid;
    opacity: 50%;
}


/*-------------------------------------------------------------*/





.zoom {
    background-position: bottom;
    transition:transform .5s;
}
.zoom:hover{

transform:scale(1.05);
}




/*THIRD SECTION*/
#third-section {
    margin-top: 50px;

    padding-top: 80px;
}

div#tihrd-section{
  
    margin-bottom:0px;
    padding-bottom: 0;
} 

#third-section h1 {
    margin: 0px 0 0 5%;
    font-size: 50px;
    margin-bottom: 20px;

}

#third-section ul {
    list-style: none;
  
}
#third-section details {
    border-bottom: 1px solid #F1F1F1;
    padding: 20px;
    padding-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
    transition: all 100ms ease-in;
}
#third-section details summary {
    outline: none;
    cursor: pointer;
    line-height: 150%;
}
#third-section details summary svg {
    position: absolute;
    right: 30px;
}
#third-section details p {
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: -1px;
        font-size: 17px;
    margin-left: 30px;
}
#third-section details[open] {
    transition: all 250ms ease-in-out;
    animation-duration: 1s;
}


#third-section details > summary:first-of-type{
    list-style-type: none;
}
#third-section details summary::-webkit-details-marker {
    display: none;
}

/*FOURTH SECTION*/
#fourth-section {
    justify-content: space-around;
    padding-top: 130px;
    padding-bottom: 30px;
}

#fourth-section h1 {
    font-size: 50px;
        margin-top: 0px;
}

#fourth-section #team-container {
    width: 45%;
}

#fourth-section ul {
    list-style: none;
    padding: 0;
}
#fourth-section ul li {
    margin-top: 10px;
}
#fourth-section h4 {
    display: inline;
    color: #FF562E;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 23px;
}
#fourth-section p {
    margin-top: 5px;
    font-size: 18px;
    margin-bottom: 40px;
}
.flex-1{
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
display: flex; 
    display: flex;
    justify-content: center;
}
.flex-2{
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
display: flex; 
    display: flex;
    justify-content: center;
}
#fourth-section #team-images {
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 15px;
    width: 45%;
    margin-top: 10px;
}


#fourth-section img {
    width: 40%;
    aspect-ratio: 1;
    border-radius: 10px;
     filter: contrast(110%);
}

.nav-lines {
    display: none;
    margin-right: 30px;
    transform: scale(1.5);
    cursor: pointer;
}
#below-images #logoNaranja2 {
    display: none !important;
}

#DoctorGrant{
    display: none;
}

#Steven{
    display: none;
}
#Field-Garmet{
    display: none;
}
#Edward-Teach{
    display: none;
}


/* MEDIA QUERYS */

@media screen and (max-width: 1020px) {

    #DoctorGrant{
        display: block;
        justify-content: center !important;
        align-items: center !important;
        margin: auto;
    }
    
    #Steven{
        display: block;
        justify-content: center !important;
        align-items: center !important;
        margin: auto;
    }
    #Field-Garmet{
        display: block;
        justify-content: center !important;
        align-items: center !important;
        margin: auto;
    }
    #Edward-Teach{
        display: block;
        justify-content: center !important;
        align-items: center !important;
        margin: auto;
    }

    #team-images{
        display: none;
 
    }

    
    #below-banner h1 {
        font-size: clamp(50px, 1em, 80px);
    }
    .separator {
        border: none;
    }
    #first-section h2 {
    text-align: center;
    }


    #first-section #picture1{
        width: 60%;
    }

    #first-section, #second-section, #roadmap, #fourth-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #first-section div, #first-section img {
        width: 85%;
        margin-bottom: 10px;
    }
    #first-section{
        margin-bottom: -40px;
        padding-bottom: 0px;
    }
  

    #roadmap #text {
        width: 75%;
        margin: auto;
    }
    #referencias {
        align-self: center !important;
        text-align: center !important;
    }
    
    #images img {
        width: 45% !important;
    }
    #images img:nth-child(1), #images img:nth-child(5) {
        display: none !important;
    }
    #below-images {
        width: auto !important;
    }

    #below-images #logoNaranja {
        display: none !important;
    }
    #below-images #logoNaranja2 {
        display: flex !important;
    }
    
    #below-images p {
        padding-left: 15px;
    }


    #FAQ {
        padding: 20px;
    }
    #fourth-section div {
        width: 80% !important;
    }
    #fourth-section ul {
    text-align: center;
    padding: 0;
    }

}

@media screen and (max-width: 650px) {

    #fourth-section img {
        width: 54%;
    }
    .banner-container img {
        width: 140%;
       margin-right: 60px;
    }

    .footer-buttons {

        margin-left: 0;
    }

    .banner-container{
        overflow: hidden;
    }

    #below-banner{
        text-align: center;
    }

#below-banner h1 {
    margin-top: 32px;
    margin-left: 0px;
    margin-left: -10px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: italic;
    font-size: 35px;
    text-align: center;
    margin-bottom: 5px;
}

#below-banner p {
    font-size: 15px;
    margin-bottom: 40px;
    margin-top: 10px;
    margin-left: 0px;

}

#subtituloPrincipal {
    display: inline;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 10px;
   
}

#colorNaranja {
    font-family: 'Roboto', sans-serif;
    color: #FF562E;
    font-size: 14px;
     display: block; 
     text-align: center;
}

#ProgetDefinition {
    font-weight: initial;
    font-family: 'Roboto', sans-serif;
    font-size: 16.5px;
    line-height: 26px;
    text-align: center;
}

#second-section{
margin-top: -80px;
}

#second-section #roadmap img {
    width: 90%;
    margin-left: 3%;
}

#roadmap {
    margin-left: 0%;

}
#RoadMap1{
margin-left: 50px;
}

#text{
    text-align: center;
}

.flex-1{
    display:block;
    align-content: space-between;
    flex-wrap: wrap;
    margin-left: 20px;
    
}

#second-section2{
    margin-top: -45px;
}

#second-section2 #images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    gap: 45px;
    padding-top: 80px;
}
#images img{
    padding: 6px;
}

.flex-2{
    display:block;
    align-content: space-between;
    flex-wrap: wrap;
    margin-left: 40px;
}

#second-section2 #below-images {
    display: block;
    flex-direction: row; 
    justify-content: center !important;
    align-items: center !important;
    margin-top: 0px;
    margin-right: 0;
    margin-inline: 20px;
    /* margin-bottom: 10px; */
}


#buton-buy{
        justify-content: center !important;
}
#below-images #logoNaranja2 {
    display: block!important;
}


#second-section2 #below-images p {
    font-size: 18px;
    display: block;
    margin-left: 5px;
    color: #dd6946;
    justify-content: center !important;
    align-items: center !important;

}
/* -------------------SECOND SECIOTN BOTON  BUY


#second-section2 #below-images a {
    display: block;
    background: #FF562E;
    padding: 14px 13px;
    border-radius: 5px;
    color: #F1F1F1;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-style: bold;
    letter-spacing: 1px;
    justify-content: center !important;
    align-items: center !important;
      margin-right: 5%;
    
}


#second-section2 #below-images a:hover {
    background: #F1F1F1;
    color: #FF562E;
    transition: background 150ms ease-in;
}
*/
#second-section2 #below-images a {
    display: block;
    border-color:white;
    border-width: 2px;
    border-style:solid;
    opacity: 50%; background: #0E0D0D;
    color:white;
    transition: background 150ms ease-in;
    padding: 14px 13px;
    border-radius: 5px;
    color: #F1F1F1;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-style: bold;
    letter-spacing: 1px;
    justify-content: center !important;
    align-items: center !important;
      margin-right: 5%;
    
}


/*--------------------------------------------------------*/
div#below-images{
    margin:0;
    text-align: center;
    
}


#third-section{
    margin-top: -3px;
}

#fourth-section{
    margin-top: -73px;
    margin-bottom: -70px;
}
#team-images img{

    padding: 4px 6px;
}
}