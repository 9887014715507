@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

/*-barra donde van las cosas-*/

.bar-nav {
    display:flex;
    align-items: center; 
    justify-content: space-between !important;
    box-sizing: border-box;
    position:sticky;
    top:0;
    background:#0E0D0D;
    padding-block: 10px;
    z-index: 100;

}



/*---------------------------*/
/*-logo con la imagen walerus-*/

#centrar-img{
    margin-top: -2px;
   margin-left: -7.2px;
  display: flex;
}

/*color del fondo */
.botonWalrus{
    background: WHITE;
    display: flex;
    width: 63px;
    height: 69px;
    border-radius: 100%;
    align-self: flex-start !important;
}
.botonWalrus:hover{
  background: #D65E48;
}
.botonWalrus:hover #logo-big{
  color: #D65E48;
}
.botonWalrus:hover  #walerus{
  color: #D65E48;
}

#logo-nav img {
     border-radius: 100%;
    height: 73px;
}

#logo-nav div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
     margin-right: -10%;
     margin-left: 5%;
}

#logo-nav h2 {
    font-size: 20px;
    margin: 0;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-style: italic;
}
#logo-nav #logo-big {
    margin-left: 22%;
    margin-bottom: -5%;
    font-size: 17px;
    padding-top: -1px;
}
#logo-nav #walerus {
    font-size: 30px;

}


/*---------------------------*/


.bar-nav ul {
    list-style: none;
    width: 70%;
    font-family: 'Inter', sans-serif;
    font-style: bold;
    justify-content: space-between !important;
     align-items: center; /*-centra los textos-*/
     margin-left: -10%;
     margin-right: -15%;
}
.bar-nav ul a {
    cursor: pointer;
    text-decoration: none;
    font-size: 17px;
}

.nav-item .active{
    cursor: pointer;
    text-decoration: none;
    font-size: 17px;
    color: #FF562E;
    font-weight: bold;
}

.bar-nav ul a:hover {
    cursor: pointer;
    text-decoration: none;
    font-size: 17px;
    color: #FF562E;
    font-weight: bold;
}

/*------------Boton BUY WALRUS-----------------------*/
/*
#boton{

    font-weight: bold;
    background: #FF562E;
    padding: 11px 10px;
    border-radius: 5px;
    color: #F1F1F1;
    font-family:'Inter',sans-serif;
    margin-right: -10px;
      border-color: #FF562E;
    border-width: 2px;
    border-style:solid;
}

#boton:hover {
    background: #0E0D0D;
    color:white;
    transition: background 150ms ease-in;
    border-color:white;
    border-width: 2px;
    border-style:solid;
}
*/

#boton{
    cursor: not-allowed;
    font-weight: bold;
    background: #0E0D0D;
    padding: 11px 10px;
    border-radius: 5px;
    color: #F1F1F1;
    font-family:'Inter',sans-serif;
    margin-right: -10px;
    color:white;
    transition: background 150ms ease-in;
    border-color:white;
    border-width: 2px;
    border-style:solid;
    opacity: 50%;

}

/*---------------------------------------*/
/*--------------------BOTON BUY WALRUS PERO EN MEDIACUERRIS------------------------------------*/
/*
#botonMorsa{
    
    font-weight: bold;
    background: #FF562E;
    padding: 11px 10px;
    border-radius: 5px;
    color: #F1F1F1;
    font-family: 'Inter', sans-serif;
      border-color: #FF562E;
    border-width: 2px;
    border-style:solid;
    display:none;
    margin-left: auto;
    
}

#botonMorsa:hover {
    background: #0E0D0D;
    color:white;
    transition: background 150ms ease-in;
    border-color:white;
    border-width: 2px;
    border-style:solid;
}
*/
#botonMorsa{

    font-weight: bold;
    background: #0E0D0D;
    color:white;
    transition: background 150ms ease-in;
    padding: 11px 10px;
    border-radius: 5px;
    color: #F1F1F1;
    font-family: 'Inter', sans-serif;
    border-color:white;
    border-width: 2px;
    border-style:solid;
    display:none;
    margin-left: auto;
    display:none;
    cursor: not-allowed;
    opacity: 50%;

}

/*-------------------------------------------------------------*/
.bar-nav #home-a {
    color: #FF562E;
}
.bar-nav .nav-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 6px;
    margin-left: -50px;
}


.menu-icon {
    display:none;
}

.flex-items{
    display: flex;
    justify-content: center;
}




@media screen and (max-width: 1020px) {

/*SON ITEMS QUE NO QUEREMOS QUE SE MUESTEN*/
    .nav-buttons {
        display: none !important;
    }
   

    #boton{
        display:none;
    }
/*--------------------------------------*/

        .botonWalrus {
        margin-left: 30px;
    }
    
    #botonMorsa{
        display:flex;
    }


    .menu-icon{
        display:block;
        margin-left: 30px;
        margin-right:20px;
        cursor: pointer;
        border-radius: 5px;;
        border-width: 2px;
        border-style:solid;
        font-size: 20px;
        transition:  350ms ease-in;
    }



    .bar-nav ul {
        display:flex;
        width: 95%;
        margin-left: 0%;
        margin-right: 0%;
    
    }

    .flex-items{

        z-index:-10000;
        flex-direction: column;
        position: absolute;
        backdrop-filter: blur(5px);
  background:rgb(14,13,13, 0.7);
        padding-top: 30px;
        padding-bottom: 30px;

        transition: all 0.5 ease-in-out;

    }

     
    #container{
        padding-top: 400px;

    }
    
    .flex-items li{
        
        text-align: center;
        margin-left: -50px;
    }
    .flex-items li:hover{
        border-radius: 10px;
        width: 200px;
         background:rgb(14,13,13, 0.7);
        
     }

    .flex-items li:last-child{
        margin-bottom: -20px;
    }
    
    .flex-items li a, .flex-items li:last-child a {
        width:100px;
        display:block;
        margin:auto;
    }
    .flex-items a{
        width: 100%;
        padding: 20px;
        
    }
 
    .fa-bars{
        padding-inline: 8.3px;
        padding-block: 10px;

    }
    .fa-times {
       
        transform: rotate(360deg);
        padding: 10px;
    
    }

    .fa-times::before{
        transition:all 0.2 ease-in-out;
    }
    
    .menu-icon .fas-bars {
        transform: rotate(180deg);
        transition:all 0.2 ease-in-out;
  
    }

    .flex-items.close{
    display: none;
    transition: all 1.5 ease-in-out;
    }


}
@media screen and (max-width: 450px) {
    #botonMorsa{
        font-family: 'Inter', sans-serif;
        font-style: italic;
        flex-direction: column;
        margin-left: 165px;
        margin-right: 0px;
        padding-left: 2px;
        padding-right: 2px;
        padding-block: 0px;
        text-align: center;
    }

    .menu-icon{
        display:block;
        margin-left: 15px;
        margin-right:15px;
        cursor: pointer;
        border-radius: 5px;;
        border-width: 2px;
        border-style:solid;
        font-size: 20px;
        transition:  350ms ease-in;
    }
}
@media screen and (max-width: 415px) {
/*-----------------------------------------------*/
/*
    #botonMorsa{
        display:flex;
        font-weight: bold;
        background: #FF562E;

        border-radius: 5px;
        color: #F1F1F1;
        font-family: 'Inter', sans-serif;
          border-color: #FF562E;
        border-width: 2px;
        border-style:solid;
        flex-direction: column;
        margin-left: 130px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-block: 1.2px;
        text-align: center;
    }
*/
#botonMorsa{
    display:flex;
    font-weight: bold;
    background: #0E0D0D;
    color:white;

    border-radius: 5px;
    color: #F1F1F1;
    font-family: 'Inter', sans-serif;
      border-color:#F1F1F1;
    border-width: 2px;
    border-style:solid;
    flex-direction: column;
    margin-left: 130px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-block: 1.2px;
    text-align: center;
}
    
/*-------------------------------------------------*/

    .menu-icon{
        display:block;
        margin-left: 15px;
        margin-right:15px;
        cursor: pointer;
        border-radius: 5px;;
        border-width: 2px;
        border-style:solid;
        font-size: 20px;
        transition:  350ms ease-in;
    }
    
}