#logos {
	display: flex;
   flex-direction: row;
justify-content: center;
align-items: center;
margin-top:40px;
margin-left: 12%;
margin-bottom: 10px;
}
#logos img{
	  height: 50px;
	  margin-right: 110px;
}

