body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #0E0D0D;

}

h1, h2, h3, h4, p, div, a {
  color: #F1F1F1;
}

.hover:hover {
  color: #FF562E;
  transition: color 250ms ease-out;
}

.common-container {
  max-width: 1200px;
  margin-inline: auto;
  flex-direction: column;

}

.flex {
  display: flex;
  justify-content: center;
}

footer {
  justify-content: space-between !important;
}

.footer-buttons {
  margin-top: 20px;
  justify-content: right !important;
  gap: 10px;
}

footer #rights {
  justify-content: right !important;
  text-align: right;
}

footer #sub {
  max-width: 50vw;
}

footer p {
  font-size: 12px;
}

